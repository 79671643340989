<template>
  <section class="container  text-transparencia">
    <layout-breadcrumb :items="breadcrumbs"></layout-breadcrumb>
    <div class="row">
      <div class="col-sm-12">
        <h2 class="font-weight-800 text-transparencia">
          Blog
        </h2>
        <p class="mt-1 mb-5 text-transparencia">Seleccione una categoría</p>
      </div>
    </div>

    <div class="row justify-content-center text-center">
      <template v-for="(categoria, index) in categorias">
        <div class="col-md-3 mt-2" :key="index">
          <button class="btn btn-block transition-hover px-3 rounded-pill" :class="categoriaActual == categoria.blogCategoriaId ? 'btn-secondary' : 'btn-transparencia'" v-on:click="mostrarPanel(categoria.blogCategoriaId)">{{ categoria.nombre }}</button>
        </div>
      </template>
    </div>

    <div class="row justify-content-center pt-5">
      <template v-for="(blog, key) in blogs">
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-2 text-center" :key="key" v-show="categoriaActual == blog.blogCategoriaId">
          <div class="card d-block card-frame card-owework  border hover-shadow-6 d-block transition-hover">
            <div class="card-img-top">
              <img class="img-fluid w-100 image-round-top cimage-slider" :src="`${fileUrl(blog.rutaImagen)}`" :alt="blog.nombres" />
            </div>
            <div class="card-body" style="height:6.3rem !important;">
              <h6 class="card-title fs-15 text-transparencia" style="height:3.9rem !important;overflow:hidden">{{ blog.titulo }}</h6>
            </div>
            <div class="card-footer text-right pt-0">
              <a class="fs-13 fw-600 anim-link-2 anim-link-2-transparencia" :href="'blog/detalle/' + blog.blogId"> <i class="fa fa-search-plus pr-1"></i>Ver más</a>
            </div>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import layoutBreadcrumb from "@/layout/layout-breadcrumb";
export default {
  name: "view-blog",
  metaInfo: {
    title: "Blog",
  },
  components: {layoutBreadcrumb},
  data: () => ({
    blogs: [],
    categorias: [],
    categoriaActual: null,
  }),
  methods: {
    mostrarPanel: function(blogCategoriaId) {
      this.categoriaActual = blogCategoriaId;
    },
    fetchDatosBlog: async function() {
      this.$http.get(this.apiRoute("Common", "ListBlogAll")).then(function(response) {
        if (!response.body.error) {
          this.blogs = response.body.data.lista;
          if (this.blogs != null && this.blogs.length > 0) {
            this.categoriaActual = this.blogs[0].blogCategoriaId;
          }
          this.categorias = response.body.data.categorias;
        }
      });
    },
  },
  mounted: async function() {
    await this.fetchDatosBlog();

    this.breadcrumbs = [
      { name: "Inicio", url: "/home", icon: "fa fa-home", active: false },
      { name: "Blogs", url: "", icon: "", active: true },
    ];
  },
};
</script>
